import React from "react"
/*import VideoPlayerJS from './../components/videoplayer';*/
import Layout from "../components/Layout/layout"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
//import getVideo from "../components/getVideo";
//import fetchVideos from "../components/fetchVideos";
import { getUser } from '../services/auth'

import { API, graphqlOperation, Storage } from "aws-amplify"
import * as queries from "../graphql/queries"

import { useState, useEffect } from "react"

	const AudioPage = ({ videoId }) => {
		
	const [videos, setVideo] = useState([])	
	
	useEffect(() => {
    onPageRendered();
  	}, []);

  	const onPageRendered = async () => {
    readVideo();
  	};

  	const readVideo = async () => {
    const getVideo = await API.graphql(graphqlOperation(queries.getVodAsset,{id: videoId }));
	console.log(getVideo.data.getVodAsset)
	setVideo(getVideo.data.getVodAsset)
	// console.log(setVideo)

	const audio_url = await Storage.get(getVideo.data.getVodAsset.mp3, { expires: 60 });
	const image_url = await Storage.get(getVideo.data.getVodAsset.image_url, { expires: 60 });

 	setVideo(prevState => ({ ...prevState, s3track: audio_url}));
 	setVideo(prevState => ({ ...prevState, image: image_url }));
 	
 	//console.log(setVideo)
 	};
	

  return <Layout page={"about"}>
        <div className="main">
          	<div className="text">
				
				<div class="md:w-2/3 min-w-full pl-20">  
					    <div>
						{(() => {
							if (getUser().email == 'wvdbossche@telenet.be' || getUser().email == 'patrick.daulie@mighty-real.com' || getUser().email == 'carlo@pipa.be' || getUser().email == 'Carolinevanesch2@gmail.com')
							//if (getUser().email.inList(['patrick.daulie@mighty-real.com','bbb','abc']))
							// <audio src={videos.s3track} controls autoPlay controlsList="nodownload"/>
							// if (getUser().email.match(/^(patrick.daulie@mighty-real.com|wvdbossche@telenet.be|carlo@pipa.be|pineapple)$/))
							{
								return(
								<div>	
								
								<h2 className="title">{videos.title}</h2>
								<p class="text"><div dangerouslySetInnerHTML={{__html:videos.description}} /></p>
								<img class="work-grid-item" src={videos.image} />
								<audio src={videos.s3track} controls autoPlay />
								</div>	
								)
							} else {
								return(
								<div>
								<h2 className="title">{videos.title}</h2>
								<p class="text"><div dangerouslySetInnerHTML={{__html:videos.description}} /></p>
								<img class="work-grid-item" src={videos.image} />
								<AudioPlayer
								//autoPlay
								src={videos.s3track}
								onPlay={e => console.log("onPlay")}
								/>
								</div>
								)
							}	
						})()}
      					</div>
	  			</div>
			</div>
		</div>
	</Layout>	
}

  
 	/*
    const [videos, setVideos] = useState([])
	useEffect(() => {
    // fetch the videos and set them to the state object
    fetchVideos().then(r => {
      const data = r?.data?.listVodAssets?.items
      setVideos(data)
    })
	}, [])
	*/
	
	/*
		{videos.map(video => (
				 <p>{video.format} Lesson</p>
				 ))}
	*/

export default AudioPage